@import 'tailwind';

@import 'libs/common-env/src/styles/vars';
@import 'libs/common-env/src/styles/mixins';
@import 'libs/common-env/src/styles/fonts';
@import 'libs/common-env/src/styles/api-loading';
@import 'libs/common-env/src/styles/material';
@import 'libs/common-env/src/styles/print';
@import 'libs/common-env/src/styles/rte';
@import 'libs/common-env/src/styles/common';


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}

h1,
h2,
h3,
h4 {
  @include font(Open Sans, 14px);
}

h2 {
  @include border-bottom(4px, $main-color, 5px);
  padding-bottom: 1px;
  margin-bottom: 10px;
  color: $secondary-color;
  margin-top: 20px;
  font-size: 20px;

  @include respond('gt-sm') {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5,
li {
  font-size: 14px;
}

a {
  color: $black;
}

a,
button {
  &:focus,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: underline;
  }
}

.website-loading {
  height: 100vh;
  width: 100vw;
  background: $white;
}

.bullet-list {
  li {
    margin-right: 10px;
    padding-left: 12.5px;
    position: relative;

    &::before {
      transform: translateY(-50%);
      content: '';
      display: inline-block;
      height: 7px;
      width: 7px;
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      background-color: $secondary-color;
    }
  }
}

address {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

select {
  outline: none;
}

label {
  font-weight: normal;
}

p:last-of-type {
  margin-bottom: 0;
}

.container,
.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}

.container-fluid {
  @include respond('gt-sm') {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.aw-item {
  padding: 15px 0;
  background-color: $white;

  @include respond('gt-sm') {
    padding: 20px;
  }

  @media screen {
    padding: 15px;
  }
}

.img-wrapper {
  @include bg-cover(cover);
  width: 100%;
}

.info {
  @include font-w(Open Sans, normal, 18px);
  background-color: $secondary-color;
  text-align: center;
  color: $white;

  &.small {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $secondary-color;
  }

  &:not(.small) {
    @include respond('gt-sm') {
      font-size: 24px;
    }
  }

  &.contact {
    > div {
      font-size: 14px;
      max-width: 100%;

      @include respond('gt-sm') {
        font-size: 18px;
      }
    }

    .content {
      width: 100%;

      @include respond('gt-sm') {
        width: auto;
      }
    }
  }

  a {
    margin-top: 10px;

    @include respond('gt-sm') {
      margin-top: 0;
      margin-left: 50px;
    }

    &:focus,
    &:hover,
    &:visited {
      color: $white;
    }
  }

  > div {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }
}

.flex {
  display: flex;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-end {
  justify-content: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ac-between {
  align-content: space-between;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.center {
  text-align: center;
}

.carousel {
  padding-bottom: 25px;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

.carousel-inner {
  > .item {
    transition: opacity 0.6s ease-in-out;
    display: block;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 100%;
  }

  > .active {
    transform: translateX(-50%);
    left: 50%;
    visibility: visible;
    opacity: 1;
  }
}

.carousel-control {
  @include bg-cover(cover);
  height: 40px;
  width: 40px;
  cursor: pointer;

  &.left {
    left: calc(50% - 50px);
    opacity: 1;
    top: auto;
  }

  &.right {
    right: calc(50% - 50px);
    opacity: 1;
    top: auto;
  }

  span::before {
    display: none;
  }
}

.carousel-indicators {
  display: none;

  li {
    border-color: $main-color;
    margin-left: 5px;
    margin-right: 5px;
    width: 12px;
    height: 12px;

    &.active {
      background-color: $main-color;
      margin-left: 5px;
      margin-right: 5px;
      height: 15px;
      width: 15px;
    }
  }
}

.my-image {
  @include bg-cover(cover);
  border-radius: 50%;
}

.google-map {
  .mobile-map {
    height: 100%;
    width: 100%;
    padding: 20px 30px;

    div {
      position: relative;
      height: 100px;
      width: 100%;
      color: $white;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        $secondary-color 20%,
        $secondary-color 33%,
        lighten($secondary-color, 7%) 50%,
        $secondary-color 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=  '$secondary-color', endColorstr='$secondary-color',GradientType=0);
    }

    label {
      margin: 0;
    }
  }
}

.hvr-blue-ocean {
  @include respond('gt-sm') {
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 20px $secondary-color;
    }
  }
}

.mat-overlay-container {
  z-index: 10000;

  .mat-overlay-pane {
    margin-top: -5px;
  }
}

.properties-item {
  cursor: pointer;

  &.project-item {
    position: relative;

    .project-sale-status-wrapper {
      width: 85px;
      height: 88px;
      overflow: hidden;
      position: absolute;
      top: -3px;
      right: -3px;

      .project-sale-status {
        width: 120px;
        position: relative;
        left: -5px;
        top: 15px;
        padding: 7px 0;
        background-image: linear-gradient(
          to bottom,
          $secondary-color 20%,
          $secondary-color 33%,
          lighten($secondary-color, 7%) 50%,
          $secondary-color 100%
        );
        text-align: center;
        color: $white;
        box-shadow: 0 0 3px lighten($black, 30%);
        transform: rotate(45deg);

        &::before,
        &::after {
          content: '';
          display: block;
          border-top: 3px solid $main-color;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          position: absolute;
          bottom: -3px;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    }
  }

  h5 {
    margin-bottom: 10px;
  }
}

ng-select {
  ::ng-deep {
    .form-control,
    .ui-select-container,
    .ui-select-match {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      border-radius: 0;
      outline: 0;
      box-shadow: none;
    }

    .btn-link {
      display: none;
    }

    .btn {
      padding: 0;
    }

    .ui-select-choices {
      border-radius: 0;
      border-color: lighten($gray, 80%);

      .ui-select-choices-row.active > a.dropdown-item {
        background-color: $main-color;
      }
    }

    .ui-select-match,
    .ui-select-search {
      display: flex;
      align-items: center;

      span {
        height: auto;
      }
    }

    .btn-default {
      background-color: transparent;
    }

    .btn-default:hover {
      background-color: transparent;
    }

    ul {
      left: auto;
      right: 0;
    }
  }
}

.main-loading-element {
  background-color: $main-color;

  &::after,
  &::before {
    color: $main-color;
  }
}

.why-us-item {
  background-color: $white;
  margin-bottom: 15px;

  @include respond('gt-sm') {
    max-width: 275px;
    margin-left: 25px;
    margin-right: 25px;
  }

  > div {
    width: 100%;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  img {
    max-width: 60px;

    @include respond('gt-xs') {
      max-width: 100px;
    }
  }

  p {
    font-size: 12px;
    color: $gray-dark;
  }

  a {
    margin-top: 10px;
  }
}

.lb-nav a.lb-prev {
  width: 50%;
}

.lb-nav a.lb-next {
  width: 50%;
}

sup {
  left: -0.4em;
  font-size: 70%;
}

mat-radio-group {
  ::ng-deep {
    .mat-radio-container {
      top: 0 !important;
    }

    .mat-radio-outer-circle {
      border-color: $white;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: $secondary-color;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: $secondary-color;
    }
  }
}

// menu animations
.menu-items-container-animation {
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translate3d(0, 0, 0);
  }
}

.child-menu-item-animation {
  position: relative;

  &.active,
  &:hover {
    .child-menu-text-animation {
      color: $main-color;

      @media screen and (max-width: $sm - 1) {
        &::before {
          display: block;
        }
      }

      @include respond('gt-sm') {
        &::after {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.menu-item-animation {
  &.active,
  &:hover {
    .menu-item-text-animation {
      color: $main-color;

      @media screen and (max-width: $sm - 1) {
        &::before {
          display: block;
        }
      }

      @include respond('gt-sm') {
        &::after {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.child-menu-text-animation,
.menu-item-text-animation {
  position: relative;
  color: $gray-dark;
  font-size: 20px;
  transition: color 0.3s ease-in-out;

  @include respond('gt-sm') {
    font-size: 16px;
  }

  &::before,
  &::after {
    content: '';
    display: none;
    position: absolute;
    background-color: $main-color;
  }

  &::before {
    width: 5px;
    height: 5px;
    top: 50%;
    left: -13px;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  &::after {
    display: block;
    height: 2px;
    width: 0;
    bottom: -3px;
    right: 0;
    transition: width 0.3s ease-in-out;
    z-index: 1;
  }
}

.parent-menu-item-animation {
  &:hover {
    @include respond('gt-sm') {
      .child-menu-animation {
        transition: opacity 0.1s ease-in-out;
        pointer-events: auto;
        opacity: 1;
      }

      .child-menu-item-animation {
        &:nth-of-type(2n),
        &:nth-of-type(2n + 1) {
          transform: translateX(0);
        }
      }
    }
  }

  .child-menu-animation {
    @include respond('gt-sm') {
      transition: opacity 0.1s 0.4s ease-in-out;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .child-menu-item-animation {
    @include respond('gt-sm') {
      transition: transform 0.4s ease-in-out;

      &:nth-of-type(2n) {
        transform: translateX(-100%);
      }

      &:nth-of-type(2n + 1) {
        transform: translateX(100%);
      }
    }
  }
}

.imagemapper-tooltip {
  position: relative;
  margin-bottom: 20px;
  padding: 3px 7px;
  border: 1px solid $gray-dark;
  font-size: 16px;
  background: $white;

  &::before,
  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    top: 100%;
    right: 0%;
    border: solid transparent;
    pointer-events: none;
  }

  &::before {
    margin-right: -1px;
  }

  &::after {
    margin-right: 0;
    border-color: $gray-dark;
    border-top-color: $white;
    border-width: 20px 0 0 15px;
  }
}
